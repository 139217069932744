import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';
import { isIosOrAndroid } from '@helpers/mobile';

export default defineNuxtRouteMiddleware(async () => {
  const { isVisibleForInvestorInApp, isVisibleForBorrowerInApp } = toRefs(useAuthStore());

  if (!isVisibleForInvestorInApp.value && !isVisibleForBorrowerInApp.value) {
    return navigateTo({ path: '/' });
  }

  if (!isIosOrAndroid()) {
    return navigateTo({ path: '/' });
  }
});
